<template>
  <div
    v-if="!loading && articles.length"
    :class="{
      'border-b border-light': !open
    }"
    class="ProductArticles"
  >
    <!-- head -->
    <div
      class="type-headline-sm desk:type-headline-base cursor-pointer flex items-center justify-between md:py-24 py-16"
      @click="open = !open"
    >
      {{ $t('productPage.info.guidesAndInspiration') }}
      <img
        v-if="!open"
        src="/icons/angle-down.svg"
        class="w-20 h-20 desk:w-24 desk:h-24"
      >
      <img
        v-else
        src="/icons/angle-up.svg"
        class="w-20 h-20 desk:w-24 desk:h-24"
      >
    </div>
    <!-- content -->
    <div
      class="overflow-hidden transition-all"
      :class="{
        'max-h-0 opacity-0': !open,
        'max-h-[3000px] opacity-100 md:mb-24 mb-16': open
      }"
    >
      <div class="grid grid-cols-2 gap-12">
        <nuxt-link
          v-for="article in articles"
          :key="article.uuid"
          :to="`/${article.full_slug}`"
          class="group"
        >
          <div class="relative overflow-hidden">
            <nuxt-img
              :src="article.content.summaryImage.filename"
              preset="standard"
              loading="lazy"
              sizes="mob375:50vw mob390:50vw mob414:50vw desk:273px"
              class="w-full object-cover aspect-wide group-hover:scale-110 transition-transform duration-300"
            />
          </div>
          <div class="px-12 pt-16 flex flex-col items-start gap-8">
            <div
              v-if="article.content.summaryCategories"
              class="type-tagline dotted-border"
            >
              {{ article.content.summaryCategories }}
            </div>
            <div class="type-headline-sm desk:type-headline-lg">
              {{ article.content.summaryTitle }}
            </div>
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductModel } from '~/models/product';
import { useGlobalContentStore } from '~/store/globalContent';
import { StoryBlokArticlePage } from '~/constants/types/storyblok';
const storyblokApi = useStoryblokApi();
const globalContent = useGlobalContentStore();

const props = defineProps<{
  productItem: ProductModel;
}>();

const open = ref(false);
const loading = ref(true);

onMounted(()=> {
  loadArticles();
});

const articles = ref<StoryBlokArticlePage[]>([]);

const loadArticles = async() => {
  // Don't match articles on category for SessionMap
  /*const filters = props.productItem.categories.map((m) => {
    return { 'category.category.id': { in: m.code } };
  }) as any[];*/
  const filters = [] as any;
  if (props.productItem.brand?.code) {
    filters.push({ 'brand.brand.id': { in: props.productItem.brand.code } });
  }

  const { data } = await storyblokApi.get('cdn/stories/', {
    version: useRuntimeConfig().public.storyblokStoriesVersion as 'draft' | 'published',
    starts_with: globalContent.articlesFolder,
    content_type: 'ArticlePage',
    excluding_fields: 'body',
    per_page: 2,
    filter_query: {
      __or: filters,
    },
  });
  if (data) {
    articles.value = data.stories;
    loading.value = false;
  }
};

</script>
